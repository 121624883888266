<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
				static:[],
			}
		},
		async mounted() {
			await this.ready();
			this.apiGetStatic()
		},
		methods: {
        	async getStatic(id){
	            return (await Gen.apirest('/static-content', {id:id}, ()=>{})).data
	        },
	        async apiGetStatic(){
	            this.static = await this.getStatic(2)
	        },
        },
	};
</script>
<template>
	<section id="content">
		<section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link :to="{name:'Index'}">{{web.mn_home}}</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">{{web.mn_privacy}}</li>
				</ol>
			</div>
		</section>
		<div class="content-wrap">
			<section class="section nobg nomargin">
				<div class="container">
					<div class="terms-privacy">
						<div class="center">
							<h2>{{ static.sc_title }}</h2>
						</div>
						<div class="row justify-content-center">
							<div class="col-lg-10">
								<div class="terms-privacy" v-html="static.sc_val"></div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	</section>
</template>